@media only screen and (max-width: 990px){
nav li {
    list-style: none;
    color: white !important;
   padding-top: 10px;
   padding-bottom: 10px;
   
}

.nav-item:focus, .nav-item:hover, .nav-item:active{
    color: white !important;
    background-color:#3c80b0;
}

.nav-link {
    display: block;

    padding: 0.5rem 1rem;
    color: white !important;
}

.nav-link a{
    color: white !important;
    padding: 7px;
}

a:link{
    color: white !important;
}
.sub-nav{
   
    color: white !important; 
    overflow:hidden;
    -webkit-transition:max-height .2s ease-in-out .2s;
    -moz-transition:max-height .2s ease-in-out .2s;
    -o-transition:max-height .2s ease-in-out .2s;
    -ms-transition:max-height .2s ease-in-out .2s;
    transition:max-height .2s ease-in-out .2s;  
    transform: scale(1);
    opacity: .90;
    background-color: #1eb6cf;
}

#navItemOne{
    display: none;
}
}