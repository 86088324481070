.careerGrid{
    width: 85%;  
    margin: auto;
}


.Cevents-right{
    margin: auto;
    width: 80%;
}


.sideImages{
    width: 100%;
    height: auto;
}

.career_one{
    background-image: url("../../webp/comeOnD.webp");

    background-repeat: no-repeat;

    background-size: cover;

    width: 70%;

    background-position: center;

    display: grid;

    align-items: center;

    margin: auto;

    height: 500px;

    border-radius: 8px;

    box-shadow: 2px 8px 16px #414142;
}

.career-box{
    display: flex;
    justify-content: start;
    position: relative;
    bottom: -40%;
    column-gap: 55px;
    padding: 35px;
}

.career-h1{
    color: white;
    font-weight: bold;
}

.eventCardGrid{
    grid-template-columns: 1fr 1fr 1fr 1fr;
    display: grid;
    row-gap: 15px;
    column-gap: 15px;
}

.yearheader{
    font-size: 50px;
    text-align: center;
    color: #1b527f;
}

@media only screen and (max-width: 750px){

    .yearheader{
        font-size: 30px;
        text-align: center;
        color: #1b527f;
    }

    .careerGrid{
        width: 95%;  
        margin: auto;
    }
    

    .eventCardGrid{
        grid-template-columns: 1fr 1fr;
        display: grid;
        row-gap: 15px;
        column-gap: 15px;
    }

.career_one{
    background-image: url("../../webp/comeOnM.webp");
    
    background-repeat: no-repeat;

    background-size: cover;

    background-position: center;

    display: grid;

    align-items: center;

    height: 330px;

    width: 90%;

    background-position: center;

    display: grid;

    align-items: center;

    margin: auto;

    border-radius: 8px;
}

.career-box{
    display: flex;
    justify-content: start;
    position: relative;
    bottom: -30%;
    column-gap: 25px;
    padding: 10px;
}

.sideImages{
    width: 100%;
    height: auto;
}

.careerGrid{
    margin: auto;
    width: 90%;
}

}