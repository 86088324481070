.Online-Links{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    width: 45%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 50px;
    padding-bottom: 0px;
}

.Online-Icon{
    
    text-align: center;
    margin-right: auto;
    margin-left: auto;
}

.Online-Icon img{
    width: 100%;
}

.boxes-Online{
    text-align: center;
    margin: 0px;
}

.triangle {
    margin-left: auto;
    margin-right: auto;
    width: 40px; 
    height: 60px; 
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;    
    border-bottom: 40px solid #1b527f;
}

#Online_one{
    display: block;
}

#Online_two{
    display: none;
}

@media only screen and (max-width: 600px){

    .Online-Links{
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 30px;
        width: 88%;
        margin-right: auto;
        margin-left: auto;
        padding-top: 50px;
        padding-bottom: 0px;
    }

}
