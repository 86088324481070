.opt-box{
    background-color: #3c80b0;
    color: white;
    padding: 10px 20px;
    text-align: left;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.opt-box h2{
    margin: 15px;
    padding: 30px;
    text-align: center;
}

.opt-button{
    text-align: center;
}

@media only screen and (max-width: 1550px){

    .opt-box h2{
        font-size: 2vw;
        margin: 15px;
        padding: 30px;
        text-align: center;
    }

}


@media only screen and (max-width: 850px){

    .opt-box h2{
        font-size: 4.7vw;
        margin: 5px;
        padding: 10px;
        text-align: center;
    }

}