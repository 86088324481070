    
    .scheduleGrid{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        width: 85%;
        margin: auto;
        column-gap: 15px;
        row-gap: 35px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-bottom: 40px;
    }

    @media only screen and (max-width: 750px){
       
        .scheduleGrid{
            grid-template-columns: 1fr 1fr;
            height: 100%;
            width: 92%;
            row-gap: 20px;
            column-gap: 7px;
        }

    }