.family-boxes{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    column-gap: 35px;
    
}



.family-boxTwo{
    margin-left: auto;
    margin-right: auto;
    display: grid;
   
}

.family-images{
    width: 100%;
    height: 100%;
}

.family-box{
    background-color: #3c80b0;
    color: white;
    padding: 30px 60px;
    text-align: left;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.15vw;

}



@media only screen and (max-width: 1550px){

    .family-box{
        background-color: #3c80b0;
        color: white;
        padding: 20px 40px;
        text-align: left;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 1.15vw;
    
    }

    .family-boxes{
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
        column-gap: 35px;
        
    }

}
@media only screen and (max-width: 1350px){

    .family-box{
        background-color: #3c80b0;
        color: white;
        padding: 10px 30px;
        text-align: left;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 1.15vw;
    
    }

    .family-boxes{
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        column-gap: 35px;
        row-gap: 25px;;
        
    }


}
@media only screen and (max-width: 1000px){

    .family-box{
        background-color: #3c80b0;
        color: white;
        padding: 10px 30px;
        text-align: left;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 2.1vw;
    
    }

    .family-boxes{
        display: grid;
        grid-template-columns: 1fr;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        column-gap: 35px;
        
    }

}


@media only screen and (max-width: 600px){

    .family-box{
        background-color: #3c80b0;
        color: white;
        padding: 10px 20px;
        text-align: left;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 2.1vw;
    
    }

    .family-box{
        background-color: #3c80b0;
        color: white;
        padding: 10px 30px;
        text-align: left;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 3.3vw;
    
    }

}