.eventCard p{
    font-size: 13px;
}

.eventCard h2{
    font-size: 18px;
}


@media only screen and (max-width: 750px){

.eventCard p{
    font-size: 12px;
}

.eventCard h2{
    font-size: 20px;
}


}