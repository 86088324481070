
   
    .tour-card{
        display: grid;
        grid-template-rows: auto auto auto;
        box-shadow: 2px 8px 16px #414142;
        border-radius: 12px;
        width: 99%;
        /* width: 75%; */
        /* width: 230px; */
        margin: auto;
        height: 100%;
    }

    .card-button{
        padding: 8px 15px;
        border-radius: 12px;
        border: none;
        background-color: #1eb6cf;
        color: white;  
        margin: 10px;  
    }

    .scheduleImage{
        width: 75%;
        padding: 6px;
    }

    .headertour{
        font-size: 22px;
    }

    .paratour{
        font-size: 18px;
    }

    @media only screen and (max-width: 750px){
        .tour-card{
            width: 98%;
        }

        .headertour{
            font-size: 16px;
        }

        .paratour{
            font-size: 14px;
        }
    }


 