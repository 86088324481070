.otherTourBox{
    margin: 5px;
    color: white;
    text-align: center;
    background-color: #3c80b0;
}

.otherTourBox p{
    padding: 30px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 68%;
    font-size: 1.25vw;
}

.otherTourNew{
    display: grid;
    grid-template-columns: 30% 70%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    width: 75%;
    align-items: center;
    color: white;
}

.otherNewP{
    margin-bottom: 0rem !important;
    padding: 0rem;
    margin: 0rem;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.titlesOtherNew{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
    width: 100%;
    margin-left: 50px;
}

.otherNewP {padding: 15px;}

.otherNewP p{
    margin-bottom: 0rem !important;
    padding: 0rem;
    margin: 0rem;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.otherNewH1{
    background-color: #1eb6cf;
    padding: 15px;
    margin-left: 10px;
    border-radius: 15px;
    padding-right: 25px;
    padding-left: 25px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 40%), 0 6px 20px 0 rgb(0 0 0 / 40%);
}

.otherNewH1 h1{
    font-size: 40px;
    margin-bottom: 0;
}

.mainTourImage{
    width: 100%;
}

.textOtherNew{
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: #3c80b0;
    padding: 45px;
    position:relative;
    overflow:hidden;

}

.textOtherNew:after{
    content: '';
    transform: rotate(180deg);
    display: block;
    position: absolute;
    background-color: white;
    width: 0; 
    height: 186px; 
    border-top: 93px solid #3c80b0;
    border-bottom: 93px solid #3c80b0;  
    border-left: 50px solid lightgrey;
    right: 0;
    top: 0;
}

.ribbonsOtherNew{
    display: grid;
    grid-template-columns: 70% 30%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    width: 75%;
    align-items: center;
    color: white;
}

.smallP{
    margin-bottom: 0rem !important;
    padding: 0rem;
    margin: 0rem;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.newSmallTitles{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
    width: 100%;
    margin-left: 50px;
}

.smallP {padding: 15px;}

.smallP p{
    margin-bottom: 0rem !important;
    padding: 0rem;
    margin: 0rem;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.smallH1{
    background-color: #1eb6cf;
    padding: 15px;
    margin-left: 30px;
    border-radius: 15px;
    padding-right: 25px;
    padding-left: 25px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 40%), 0 6px 20px 0 rgb(0 0 0 / 40%);
}

.smallH1 h1{
    font-size: 40px;
    margin-bottom: 0;
}


.mainTourImage{
    width: 100%;
}

.newSmallOther{
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: #3c80b0;
    padding: 45px;
    position:relative;
    overflow:hidden;

}

.newSmallOther:after{
    content: '';
    transform: rotate(180deg);
    display: block;
    position: absolute;
    background-color: white;
    width: 0; 
    height: 186px; 
    border-top: 93px solid #3c80b0;
    border-bottom: 93px solid #3c80b0;  
    border-right: 50px solid lightgrey;
    left: 0;
    top: 0;
}


@media only screen and (max-width: 1700px){

    .smallH1 h1{
        font-size: 35px;

    }

    .smallP h1{
        font-size: 30px;
    }

    .otherNewP h1{
        font-size: 30px;
    }

    .otherNewH1 h1{
        font-size: 35px;
    }

    .titlesOtherNew{
        display: flex;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        width: 100%;
        margin-left: 10px;
    }

    .newSmallTitles{
        display: flex;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        width: 100%;
        margin-left: 10px;
    }

    


}
@media only screen and (max-width: 1520px){

    .textOtherNew:after{
        content: '';
        transform: rotate(180deg);
        display: block;
        position: absolute;
        background-color: white;
        width: 0; 
        height: 130px; 
        border-top: 65px solid #3c80b0;
        border-bottom: 65px solid #3c80b0;  
        border-left: 30px solid lightgrey;
        right: 0;
        top: 0;
    }

    .newSmallOther:after{
        content: '';
        transform: rotate(180deg);
        display: block;
        position: absolute;
        background-color: white;
        width: 0; 
        height: 130px; 
        border-top: 65px solid #3c80b0;
        border-bottom: 65px solid #3c80b0;  
        border-right: 30px solid lightgrey;
        left: 0;
        top: 0;
    }

    .newSmallOther{
        margin-top: 15px;
        margin-bottom: 15px;
        background-color: #3c80b0;
        padding: 20px;
        position:relative;
        overflow:hidden;
    
    }

    .textOtherNew{
        margin-top: 15px;
        margin-bottom: 15px;
        background-color: #3c80b0;
        padding: 20px;
        position:relative;
        overflow:hidden;
    
    }

}
@media only screen and (max-width: 1350px){
    .smallH1 h1{
        font-size: 28px;
        margin-bottom: 0;

    }

    .smallP h1{
        font-size: 22px;
    }

    .otherNewP h1{
        font-size: 22px;
    }

    .otherNewH1 h1{
        font-size: 28px;
    }

    .titlesOtherNew{
        display: flex;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        width: 100%;
        margin-left: 10px;
    }

    .newSmallTitles{
        display: flex;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        width: 100%;
        margin-left: 10px;
    }
}
@media only screen and (max-width: 1160px){

    .otherTourPara{
        font-size: 1.75vw !important;
    }


    .ribbonsOtherNew{
        display: grid;
        grid-template-columns: 70% 30%;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        width: 90%;
        align-items: center;
        color: white;
    }

    .otherTourNew{
        display: grid;
        grid-template-columns: 30% 70%;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        width: 90%;
        align-items: center;
        color: white;
    }
}
@media only screen and (max-width: 800px){
    .otherH1 h1{
        font-size: 22px;
    }

    .smallP h1{
        font-size: 18px;
    }

    .otherNewP h1{
        font-size: 18px;
        margin-bottom: 0;
    }

    .otherNewH1 h1{
        font-size: 22px;
    }

    .titlesOtherNew{
        display: flex;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        width: 100%;
        margin-left: 0px;
    }

    .newSmallTitles{
        display: flex;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        width: 100%;
        margin-left: 0px;
    }


    .ribbonsOtherNew{
        display: grid;
        grid-template-columns: 70% 30%;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        width: 100%;
        align-items: center;
        color: white;
    }

    .otherTourNew{
        display: grid;
        grid-template-columns: 30% 70%;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        width: 100%;
        align-items: center;
        color: white;
    }

    .otherTourBox p{
        padding: 15px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        width: 80%;
    }

    .otherTourPara{
        font-size: 2.3vw !important;
    }
}
@media only screen and (max-width: 600px){

    .otherTourPara{
        font-size: 3.25vw !important;
    }

    .otherTourBox p{
        padding: 5px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        width: 95% !important;
    }

    .titlesOtherNew{
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        width: 100%;
        margin-left: 0px;
    }

    .newSmallTitles{
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        width: 100%;
        margin-left: 0px;
    }


    .otherTourNew{
        display: grid;
        grid-template-columns: 1fr;
        margin-left: auto;
        margin-right: auto;
        padding: 0px;
        width: 95%;
        align-items: center;
        color: white;
    }

    .ribbonsOtherNew{
        display: grid;
        grid-template-columns: 1fr;
        margin-left: auto;
        margin-right: auto;
        padding: 0px;
        width: 95%;
        align-items: center;
        color: white;
    }

    .otherNewH1{
        margin-bottom: 0;
        background-color: #1eb6cf;
        margin-left: auto;
        margin-right: auto;
        width: 35%;
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 40%), 0 6px 20px 0 rgb(0 0 0 / 40%);
    }

    .smallH1{
        margin-bottom: 0;
        background-color: #1eb6cf;
        margin-left: auto;
        margin-right: auto;
        width: 35%;
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 40%), 0 6px 20px 0 rgb(0 0 0 / 40%);
    }

    .textOtherNew:after{
        content: '';
        transform: rotate(180deg);
        display: block;
        position: absolute;
        background-color: white;
        width: 0; 
        height: 0px; 
        border-top: 0px solid #3c80b0;
        border-bottom: 0px solid #3c80b0;  
        border-left: 0px solid #3c80b0;
        right: 0;
        top: 0;
    }

    .newSmallOther:after{
        content: '';
        transform: rotate(180deg);
        display: block;
        position: absolute;
        background-color: white;
        width: 0; 
        height: 0px; 
        border-top: 0px solid #3c80b0;
        border-bottom: 0px solid #3c80b0;  
        border-left: 0px solid #3c80b0;
        left: 0;
        top: 0;
    }
}


