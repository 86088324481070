/* The Modal (background) */
.modal-C {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 100000; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    
  }
  .modal-content form{
    color: white;
  }

  .modal-content h1{
    color: white;
  }

  .modal-content p{
    color: white;
  }

  .modal-content h2{
    color: white;
  }


  input[type="submit"]{
    background: #1eb6cf;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 15px;
    font-size: 22px;

}
  
  /* Modal Content */
  .modal-content {
    background-color: #3c80b0;
    margin: auto;
    padding: 40px;
    border: 1px solid #888;
    width: 550px;
  }
  
  /* The Close Button */
  .close{
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    right: 2%;
    top: 0%;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  input[type="text"],
input[type="password"],
    select,
    textarea,
    input[type="tel"] {
      width: 100%;
      padding: 2px;
      border: 1px solid #ccc;
      border-radius: 4px;
      resize: vertical;
    }

  @media only screen and (max-width: 768px)
  {
    .modal-content {
        background-color: #3c80b0;
        margin: auto;
        padding: 40px;
        border: 1px solid #888;
      width: 95%;
      max-height: 800px;
    }
  }