.ptourBox{
    margin: 5px;
    color: white;
    text-align: center;
    background-color: #3c80b0;
}

.ptourBox p{
    padding: 30px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 68%;
    font-size: 1.25vw;
}

.titleRibbons{
    display: grid;
    grid-template-columns: 30% 70%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    width: 75%;
    align-items: center;
    color: white;
}

.titleP{
    margin-bottom: 0rem !important;
    padding: 0rem;
    margin: 0rem;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.titlesText{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
    width: 100%;
    margin-left: 30px;
}

.titleP {padding: 15px;}

.titleP p{
    margin-bottom: 0rem !important;
    padding: 0rem;
    margin: 0rem;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.titleH1{
    background-color: #1eb6cf;
    padding: 15px;
    margin-left: 30px;
    border-radius: 15px;
    padding-right: 25px;
    padding-left: 25px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 40%), 0 6px 20px 0 rgb(0 0 0 / 40%);
}

.titleH1 h1{
    font-size: 40px;
  
    margin-bottom: 0;
}

.mainTourImage{
    width: 100%;
}

.ribbonText{
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: #3c80b0;
    padding: 45px;
    position:relative;
    overflow:hidden;

}

.ribbonText:after{
    content: '';
    transform: rotate(180deg);
    display: block;
    position: absolute;
    background-color: white;
    width: 0; 
    height: 186px; 
    border-top: 93px solid #3c80b0;
    border-bottom: 93px solid #3c80b0;  
    border-left: 50px solid lightgrey;
    right: 0;
    top: 0;
}

.otherRibbons{
    display: grid;
    grid-template-columns: 70% 30%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    width: 75%;
    align-items: center;
    color: white;
}

.otherP{
    margin-bottom: 0rem !important;
    padding: 0rem;
    margin: 0rem;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.otherTitles{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
    width: 100%;
    margin-left: 50px;
}

.otherP {padding: 15px;}

.otherP p{
    margin-bottom: 0rem !important;
    padding: 0rem;
    margin: 0rem;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.otherH1{
    background-color: #1eb6cf;
    padding: 15px;
    margin-left: 30px;
    border-radius: 15px;
    padding-right: 25px;
    padding-left: 25px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 40%), 0 6px 20px 0 rgb(0 0 0 / 40%);
}

.otherH1 h1{
    font-size: 40px;
    
    margin-bottom: 0;
}


.mainTourImage{
    width: 100%;
}

.otherText{
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: #3c80b0;
    padding: 45px;
    position:relative;
    overflow:hidden;

}

.otherText:after{
    content: '';
    transform: rotate(180deg);
    display: block;
    position: absolute;
    background-color: white;
    width: 0; 
    height: 186px; 
    border-top: 93px solid #3c80b0;
    border-bottom: 93px solid #3c80b0;  
    border-right: 50px solid lightgrey;
    left: 0;
    top: 0;
}


@media only screen and (max-width: 1700px){

    .otherH1 h1{
        font-size: 35px;
    }

    .otherP h1{
        font-size: 30px;
    }

    .titleP h1{
        font-size: 30px;
    }

    .titleH1 h1{
        font-size: 35px;
    }

    .titlesText{
        display: flex;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        width: 100%;
        margin-left: 10px;
    }

    .otherTitles{
        display: flex;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        width: 100%;
        margin-left: 10px;
    }

    


}
@media only screen and (max-width: 1520px){

    .ribbonText:after{
        content: '';
        transform: rotate(180deg);
        display: block;
        position: absolute;
        background-color: white;
        width: 0; 
        height: 130px; 
        border-top: 65px solid #3c80b0;
        border-bottom: 65px solid #3c80b0;  
        border-left: 30px solid lightgrey;
        right: 0;
        top: 0;
    }

    .otherText:after{
        content: '';
        transform: rotate(180deg);
        display: block;
        position: absolute;
        background-color: white;
        width: 0; 
        height: 130px; 
        border-top: 65px solid #3c80b0;
        border-bottom: 65px solid #3c80b0;  
        border-right: 30px solid lightgrey;
        left: 0;
        top: 0;
    }

    .otherText{
        margin-top: 15px;
        margin-bottom: 15px;
        background-color: #3c80b0;
        padding: 20px;
        position:relative;
        overflow:hidden;
    
    }

    .ribbonText{
        margin-top: 15px;
        margin-bottom: 15px;
        background-color: #3c80b0;
        padding: 20px;
        position:relative;
        overflow:hidden;
    
    }

}
@media only screen and (max-width: 1330px){
    .otherH1 h1{
        font-size: 28px;
    }

    .otherH{
        margin-left: 30px;
    }

    .otherP h1{
        font-size: 22px;
    }

    .titleP h1{
        font-size: 22px;
    }

    .titleH1 h1{
        font-size: 28px;
    }

    .titlesText{
        display: flex;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        width: 100%;
        margin-left: 10px;
    }

    .otherTitles{
        display: flex;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        width: 100%;
        margin-left: 10px;
    }
}
@media only screen and (max-width: 1150px){

    .ptourPara{
        font-size: 1.75vw !important;
    }


    .otherRibbons{
        display: grid;
        grid-template-columns: 70% 30%;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        width: 90%;
        align-items: center;
        color: white;
    }

    .titleRibbons{
        display: grid;
        grid-template-columns: 30% 70%;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        width: 90%;
        align-items: center;
        color: white;
    }
}
@media only screen and (max-width: 800px){
    .otherH1 h1{
        font-size: 22px;
        
        margin-bottom: 0;
    }

    .otherP h1{
        font-size: 18px;
    }

    .titleP h1{
        font-size: 18px;
    }

    .titleH1 h1{
        font-size: 22px;
       
        margin-bottom: 0;
    }

    .titlesText{
        display: flex;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        width: 100%;
        margin-left: 0px;
    }

    .otherTitles{
        display: flex;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        width: 100%;
        margin-left: 0px;
    }


    .otherRibbons{
        display: grid;
        grid-template-columns: 70% 30%;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        width: 100%;
        align-items: center;
        color: white;
    }

    .titleRibbons{
        display: grid;
        grid-template-columns: 30% 70%;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        width: 100%;
        align-items: center;
        color: white;
    }

    .ptourBox p{
        padding: 15px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        width: 80%;
    }

    .ptourPara{
        font-size: 2.3vw !important;
    }
}
@media only screen and (max-width: 600px){

    .ptourPara{
        font-size: 3.25vw !important;
    }

    .ptourBox p{
        padding: 5px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        width: 95% !important;
    }

    .titlesText{
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        width: 100%;
        margin-left: 0px;
    }

    .otherTitles{
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        width: 100%;
        margin-left: 0px;
    }


    .titleRibbons{
        display: grid;
        grid-template-columns: 1fr;
        margin-left: auto;
        margin-right: auto;
        padding: 0px;
        width: 95%;
        align-items: center;
        color: white;
    }

    .otherRibbons{
        display: grid;
        grid-template-columns: 1fr;
        margin-left: auto;
        margin-right: auto;
        padding: 0px;
        width: 95%;
        align-items: center;
        color: white;
    }

    .titleH1{
        
        margin-bottom: 0;
        background-color: #1eb6cf;
        margin-left: auto;
        margin-right: auto;
        width: 35%;
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 40%), 0 6px 20px 0 rgb(0 0 0 / 40%);
    }

    .otherH1{
       
        margin-bottom: 0;
        background-color: #1eb6cf;
        margin-left: auto;
        margin-right: auto;
        width: 35%;
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 40%), 0 6px 20px 0 rgb(0 0 0 / 40%);
    }

    .ribbonText:after{
        content: '';
        transform: rotate(180deg);
        display: block;
        position: absolute;
        background-color: white;
        width: 0; 
        height: 0px; 
        border-top: 0px solid #3c80b0;
        border-bottom: 0px solid #3c80b0;  
        border-left: 0px solid #3c80b0;
        right: 0;
        top: 0;
    }

    .otherText:after{
        content: '';
        transform: rotate(180deg);
        display: block;
        position: absolute;
        background-color: white;
        width: 0; 
        height: 0px; 
        border-top: 0px solid #3c80b0;
        border-bottom: 0px solid #3c80b0;  
        border-left: 0px solid #3c80b0;
        left: 0;
        top: 0;
    }
}


