.top-header{
        background-color: #1b527f;
        padding: 20px;
        text-align: left;        
        color: white;
        font-size: 23px;
        margin-bottom: 0;
        width: 70%;
        margin-left: auto;
        margin-right: auto;

}

.top-header h1{
        margin-left: 30px;
}

.social-button{
        width: 55%;
}

.pad-follow{
        padding-left: 20px;
        padding: 10px;
        color: white;
        text-align: left;
}

.pad-follow{
        padding: 40px;
        font-size: 20px;
}

.boxes-f{
        display: grid;
        grid-template-columns: 1fr 1fr;
        /* column-gap: 25px; */
        align-items: center;
        padding: 25px;
    }
    
    
    .box-f{
        background-color: #3c80b0;
        height: 100%;
        width: 90%;
        margin-right: auto;
        margin-left: auto;
        align-items: center;
    }

    .follow-bg {
        background-color: lightgrey;
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        
    }

    .follow-img{
        width: 100%;
    }

    .follow-button{
        padding: 11px 30px;
        border-radius: 30px;
        border: none;
        background-color: #1eb6cf;
        color: white;  
        margin-top: 25px; 
       
    }

    .social-p{
            font-size: 20px;
            width: 50%;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            padding: 5px;
    }

    .social-d{
        margin-left: auto;
        margin-right: auto;
        display: grid;
        grid-template-columns: repeat(4,auto);
        align-items: center;
        width: 80%;
        text-align: center;
    }

    .social-d img{
            margin: 20px;
    }

    .social-button{
        width: 50%;
}


    @media only screen and (max-width: 1750px){
        .pad-follow{
                padding-left: 18px;
                padding: 10px;
                color: white;
                text-align: left;
        }
        
        .pad-follow{
                padding: 30px;
                font-size: 18px;
        }

        .follow-button{
                padding: 11px 30px;
                border-radius: 30px;
                border: none;
                background-color: #1eb6cf;
                color: white;  
                margin-top: 10px; 
               
            }

            .social-p{
                font-size: 20px;
                width: 70%;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                padding: 20px;
        }

        .social-button{
                width: 50%;
        }


        }


        @media only screen and (max-width: 1520px){

                .social-button{
                        width: 50%;
                }
                .pad-follow{
                        padding-left: 18px;
                        padding: 10px;
                        color: white;
                        text-align: left;
                }
                
                .pad-follow{
                        padding: 22px;
                        font-size: 16px;
                }
        
                .follow-button{
                        padding: 11px 30px;
                        border-radius: 30px;
                        border: none;
                        background-color: #1eb6cf;
                        color: white;  
                        margin-top: 5px; 
                       
                    }

                    .box-f{
                        background-color: #3c80b0;
                        height: 100%;
                        width: 95%;
                        margin-right: auto;
                        margin-left: auto;
                        align-items: center;
                    }

        
        
                }


                @media only screen and (max-width: 1330px){

                        .follow-bg {
                                background-color: lightgrey;
                                width: 80%;
                                margin-left: auto;
                                margin-right: auto;
                                
                            }

                        .top-header{
                                background-color: #1b527f;
                                padding: 20px;
                                text-align: left;        
                                color: white;
                                font-size: 23px;
                                margin-bottom: 0;
                                width: 80%;
                                margin-left: auto;
                                margin-right: auto;
                        
                        }
                        .pad-follow{
                                padding-left: 12px;
                                padding: 5px;
                                color: white;
                                text-align: left;
                        }
                        
                        .pad-follow{
                                padding: 18px;
                                font-size: 15px;
                        }
                
                        .follow-button{
                                padding: 11px 30px;
                                border-radius: 30px;
                                border: none;
                                background-color: #1eb6cf;
                                color: white;  
                                margin-top: 5px; 
                               
                            }
        
                            .box-f{
                                background-color: #3c80b0;
                                height: 100%;
                                width: 95%;
                                margin-right: auto;
                                margin-left: auto;
                                align-items: center;
                            }

                            .social-button{
                                width: 50%;
                        }
        
                
                
                        }

                        @media only screen and (max-width: 1040px){
                                .pad-follow{
                                        padding: 18px;
                                        font-size: 13px;
                                }  
                        }

                        @media only screen and (max-width: 970px){

                                .pad-follow{
                                        padding: 18px;
                                        font-size: 15px;
                                }

                                .top-header h1{
                                        margin-left: 5px;
                                }
                                

                                .boxes-f{
                                        display: grid;
                                        grid-template-columns: 1fr;
                                        /* column-gap: 25px; */
                                        align-items: center;
                                        padding: 25px;
                                        row-gap: 25px;
                                    }
                                    
                                    
                                    .box-f{
                                        background-color: #3c80b0;
                                        height: 100%;
                                        width: 100%;
                                        margin-right: auto;
                                        margin-left: auto;
                                        align-items: center;
                                    }

                                    .follow-bg {
                                        background-color: lightgrey;
                                        width: 90%;
                                        margin-left: auto;
                                        margin-right: auto;
                                        
                                    }
        
                                .top-header{
                                        background-color: #1b527f;
                                        padding: 20px;
                                        text-align: left;        
                                        color: white;
                                        font-size: 23px;
                                        margin-bottom: 0;
                                        width: 90%;
                                        margin-left: auto;
                                        margin-right: auto;
                                
                                }

                                .social-p{
                                        font-size: 15px;
                                        width: 85%;
                                        text-align: center;
                                        margin-left: auto;
                                        margin-right: auto;
                                        padding: 20px;
                                }

                                .social-button{
                                        width: 100%;
                                }

                                .social-d{
                                        margin-left: auto;
                                        margin-right: auto;
                                        display: grid;
                                        grid-template-columns: repeat(4,auto);
                                        align-items: center;
                                        width: 90%;
                                    }

                                    .social-d img{
                                        margin: 0px;
                                        padding: 10px;
                                }



                        }              