.back_one{
    background-image: url("../../webp/comeOnD.webp");

    background-repeat: no-repeat;

    background-size: cover;

    background-position: center;

    display: grid;

    align-items: center;

    height: 680px;
}

.textbox h1{
    color: white;
    font-weight: bold;
}

.back_two{
    background-image: url("../../webp/tipsD.webp");

    background-repeat: no-repeat;

    background-size: cover;

    background-position: center;

    display: grid;

    align-items: center;

    height: 680px;
}

.back_three{

    background-image: url("../../webp/tourNewD.webp");

    background-repeat: no-repeat;

    background-size: cover;

    background-position: center;

    display: grid;

    align-items: center;

    height: 680px;
    
}

.back_four{

    background-image: url("../../webp/TourPerformDesktop.webp");

    background-repeat: no-repeat;

    background-size: cover;

    background-position: center;

    display: grid;

    align-items: center;

    height: 680px;
    
}

.back_five{

    background-image: url("../../webp/sponsD.webp");

    background-repeat: no-repeat;

    background-size: cover;

    background-position: center;

    display: grid;

    align-items: center;

    height: 680px;
    
}

.back_six{

    background-image: url("../../webp/shopTopD.webp");

    background-repeat: no-repeat;

    background-size: cover;

    background-position: center;

    display: grid;

    align-items: center;

    height: 680px;
    
}

.SliderCSS{
position: relative;
margin: 0 auto;
overflow: hidden;
}

.car_box{
    position: relative;
    left: 12%;
    bottom: -25%;
    padding: 25px;
    color: white;
}

.car_button{
    padding: 11px 30px;
    border-radius: 30px;
    border: none;
    background-color: #1eb6cf;
    color: white;  
    margin: 5px;  
}

.title_one {
    font-size: 68px !important;
}

.back_one h1{
    font-size: 26.5px;
}



@media only screen and (max-width: 1750px){

.back_one{
    background-image: url("../../webp/comeOnD.webp");

    background-repeat: no-repeat;

    background-size: cover;

    background-position: center;

    display: grid;

    align-items: center;

    height: 580px;
}

.back_two{
    background-image: url("../../webp/tipsD.webp");

    background-repeat: no-repeat;

    background-size: cover;

    background-position: center;

    display: grid;

    align-items: center;

    height: 580px;
}

.back_three{

    background-image: url("../../webp/tourNewD.webp");

    background-repeat: no-repeat;

    background-size: cover;

    background-position: center;

    display: grid;

    align-items: center;

    height: 580px;
    
}

.back_four{

    background-image: url("../../webp/TourPerformDesktop.webp");

    background-repeat: no-repeat;

    background-size: cover;

    background-position: center;

    display: grid;

    align-items: center;

    height: 580px;
    
}

.back_five{

    background-image: url("../../webp/sponsD.webp");

    background-repeat: no-repeat;

    background-size: cover;

    background-position: center;

    display: grid;

    align-items: center;

    height: 580px;
    
}

.back_six{

    background-image: url("../../webp/shopTopD.webp");

    background-repeat: no-repeat;

    background-size: cover;

    background-position: center;

    display: grid;

    align-items: center;

    height: 580px;
    
}

.SliderCSS{
position: relative;
margin: 0 auto;
overflow: hidden;
}

.car_box{
    position: relative;
    left: 12%;
    bottom: -25%;
    padding: 25px;
    color: white;
}

.car_button{
    padding: 11px 30px;
    border-radius: 30px;
    border: none;
    background-color: #1eb6cf;
    color: white;  
    margin: 5px;  
}

.title_one {
    font-size: 68px !important;
}

.back_one h1{
    font-size: 26.5px;
}

}

@media only screen and (max-width: 1250px){
    .back_one{
        background-image: url("../../webp/comeOnL.webp");
    
        background-repeat: no-repeat;
    
        background-size: cover;
    
        background-position: center;
    
        display: grid;
    
        align-items: center;
    
        height: 440px;
    }

    .back_two{
        background-image: url("../../webp/tipsL.webp");
    
        background-repeat: no-repeat;
    
        background-size: cover;
    
        background-position: center;
    
        display: grid;
    
        align-items: center;
    
        height: 440px;
    }
    
    .back_three{
    
        background-image: url("../../webp/tourNewL.webp");
    
        background-repeat: no-repeat;
    
        background-size: cover;
    
        background-position: center;
    
        display: grid;
    
        align-items: center;
    
        height: 440px;
        
    }

    .back_five{

        background-image: url("../../webp/sponsL.webp");
    
        background-repeat: no-repeat;
    
        background-size: cover;
    
        background-position: center;
    
        display: grid;
    
        align-items: center;
    
        height: 440px;
        
    }
    
    .back_six{
    
        background-image: url("../../webp/shopTopL.webp");
    
        background-repeat: no-repeat;
    
        background-size: cover;
    
        background-position: center;
    
        display: grid;
    
        align-items: center;
    
        height: 440px;
        
    }

    .car_box{
        position: relative;
        left: 10%;
        bottom: -20%;
        padding: 10px;
        color: white;
    }

    .title_one {
        font-size: 40px !important;
    }

    .back_one h1{
        font-size: 24px;
    }

    .back_two h1{
        font-size: 24px;
    }

}

@media only screen and (max-width: 750px){

    .car_box{
        position: relative;
        left: 12%;
        bottom: -25%;
        padding: 10px;
        color: white;
    }

    .back_one{
        background-image: url("../../webp/comeOnM.webp");
    
        background-repeat: no-repeat;
    
        background-size: cover;
    
        background-position: center;
    
        display: grid;
    
        align-items: center;
    
        height: 330px;
    }

    .back_two{
        background-image: url("../../webp/tipsM.webp");
    
        background-repeat: no-repeat;
    
        background-size: cover;
    
        background-position: center;
    
        display: grid;
    
        align-items: center;
    
        height: 330px;
    }
    
    .back_three{
    
        background-image: url("../../webp/tourNewM.webp");
    
        background-repeat: no-repeat;
    
        background-size: cover;
    
        background-position: center;
    
        display: grid;
    
        align-items: center;
    
        height: 330px;
        
    }

    .back_five{

        background-image: url("../../webp/sponsM.webp");
    
        background-repeat: no-repeat;
    
        background-size: cover;
    
        background-position: center;
    
        display: grid;
    
        align-items: center;
    
        height: 330px;
        
    }
    
    .back_six{
    
        background-image: url("../../webp/shopTopM.webp");
    
        background-repeat: no-repeat;
    
        background-size: cover;
    
        background-position: center;
    
        display: grid;
    
        align-items: center;
    
        height: 330px;
        
    }

    .car_box{
        position: relative;
        left: 10%;
        bottom: -10%;
        padding: 10px;
        color: white;
    }

    .title_one {
        font-size: 24px !important;
    }

    .back_two h1{
        font-size: 16px;
    }

    .back_one h1{
        font-size: 16px;
    }

    .car_button{
        padding: 11px 15px;
        border-radius: 30px;
        border: none;
        background-color: #1eb6cf;
        color: white;  
        margin: 5px;  
    }

}