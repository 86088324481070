.gradient-bar{
    background-image: linear-gradient(to right, #1b527f, #3c80b0, #1eb6cf);
    width: 100%;
    height: 12px;
}

.hex{
    color:#1eb6cf;
    background: #3c80b0;
    border: #1b527f;
}

.page-Headers{
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: 30px;
    color: #1b527f;
}

.page-Headers h1{
    font-size: 3rem;
    font-weight: bold;
}


.middleHeader{
    padding: 37px;
    color: #1b527f;
    font-weight: bold;
}



@media only screen and (max-width: 1000px){

    .page-Headers{
        text-align: left;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        margin-top: 30px;
        color: #1b527f;
    }

    


}