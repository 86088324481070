.majorBox{
    margin: 5px;
    color: white;
    text-align: center;
    background-color: #3c80b0;
}

.majorBox p{
    padding: 30px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 68%;
    font-size: 1.25vw;
}

.majorRibbons{
    display: grid;
    grid-template-columns: 30% 70%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    width: 75%;
    align-items: center;
    color: white;
}

.majorP{
    margin-bottom: 0rem !important;
    padding: 0rem;
    margin: 0rem;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.titlesmajorText{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
    width: 100%;
    margin-left: 50px;
}

.majorP {padding: 15px;}

.majorP p{
    margin-bottom: 0rem !important;
    padding: 0rem;
    margin: 0rem;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.majorH1{
    background-color: #1eb6cf;
    padding: 15px;
    margin-left: 30px;
    border-radius: 15px;
    padding-right: 25px;
    padding-left: 25px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 40%), 0 6px 20px 0 rgb(0 0 0 / 40%);
}

.majorH1 h1{
    font-size: 40px;
    margin-bottom: 0;
}

.mainTourImage{
    width: 100%;
}

.majorText{
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: #3c80b0;
    padding: 45px;
    position:relative;
    overflow:hidden;

}

.majorText:after{
    content: '';
    transform: rotate(180deg);
    display: block;
    position: absolute;
    background-color: white;
    width: 0; 
    height: 186px; 
    border-top: 93px solid #3c80b0;
    border-bottom: 93px solid #3c80b0;  
    border-left: 50px solid lightgrey;
    right: 0;
    top: 0;
}

.othermajor{
    display: grid;
    grid-template-columns: 70% 30%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    width: 75%;
    align-items: center;
    color: white;
}

.othermajorP{
    margin-bottom: 0rem !important;
    padding: 0rem;
    margin: 0rem;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.othermajorTitles{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
    width: 100%;
    margin-left: 50px;
}

.othermajorP {padding: 15px;}

.othermajorP p{
    margin-bottom: 0rem !important;
    padding: 0rem;
    margin: 0rem;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.othermajorH1{
    background-color: #1eb6cf;
    padding: 15px;
    margin-left: 30px;
    border-radius: 15px;
    padding-right: 25px;
    padding-left: 25px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 40%), 0 6px 20px 0 rgb(0 0 0 / 40%);
}

.othermajorH1 h1{
    font-size: 40px;
    margin-bottom: 0;
}


.mainTourImage{
    width: 100%;
}

.othermajorText{
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: #3c80b0;
    padding: 45px;
    position:relative;
    overflow:hidden;

}

.othermajorText:after{
    content: '';
    transform: rotate(180deg);
    display: block;
    position: absolute;
    background-color: white;
    width: 0; 
    height: 186px; 
    border-top: 93px solid #3c80b0;
    border-bottom: 93px solid #3c80b0;  
    border-right: 50px solid lightgrey;
    left: 0;
    top: 0;
}


@media only screen and (max-width: 1700px){

    .othemajorrH1 h1{
        font-size: 35px;
    }

    .othermajorP h1{
        font-size: 30px;
    }

    .majorP h1{
        font-size: 30px;
    }

    .majorH1 h1{
        font-size: 35px;
    }

    .titlesmajorText{
        display: flex;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        width: 100%;
        margin-left: 10px;
    }

    .othermajorTitles{
        display: flex;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        width: 100%;
        margin-left: 10px;
    }

    


}
@media only screen and (max-width: 1520px){

    .majorText:after{
        content: '';
        transform: rotate(180deg);
        display: block;
        position: absolute;
        background-color: white;
        width: 0; 
        height: 130px; 
        border-top: 65px solid #3c80b0;
        border-bottom: 65px solid #3c80b0;  
        border-left: 30px solid lightgrey;
        right: 0;
        top: 0;
    }

    .othermajorText:after{
        content: '';
        transform: rotate(180deg);
        display: block;
        position: absolute;
        background-color: white;
        width: 0; 
        height: 130px; 
        border-top: 65px solid #3c80b0;
        border-bottom: 65px solid #3c80b0;  
        border-right: 30px solid lightgrey;
        left: 0;
        top: 0;
    }

    .othermajorText{
        margin-top: 15px;
        margin-bottom: 15px;
        background-color: #3c80b0;
        padding: 20px;
        position:relative;
        overflow:hidden;
    
    }

    .majorText{
        margin-top: 15px;
        margin-bottom: 15px;
        background-color: #3c80b0;
        padding: 20px;
        position:relative;
        overflow:hidden;
    
    }

}
@media only screen and (max-width: 1370px){
    .othermajorH1 h1{
        font-size: 28px;
    }

    .othermajorP h1{
        font-size: 22px;
    }

    .majorP h1{
        font-size: 22px;
    }

    .majorH1 h1{
        font-size: 28px;
    }

    .titlesmajorText{
        display: flex;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        width: 100%;
        margin-left: 10px;
    }

    .othermajorTitles{
        display: flex;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        width: 100%;
        margin-left: 10px;
    }
}
@media only screen and (max-width: 1170px){

    .majorPara{
        font-size: 1.75vw !important;
    }


    .othermajor{
        display: grid;
        grid-template-columns: 70% 30%;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        width: 90%;
        align-items: center;
        color: white;
    }

    .majorRibbons{
        display: grid;
        grid-template-columns: 30% 70%;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        width: 90%;
        align-items: center;
        color: white;
    }
}
@media only screen and (max-width: 800px){
    .othermajorH1 h1{
        font-size: 22px;
        margin-bottom: 0;
    }

    .othermajorP h1{
        font-size: 18px;
    }

    .majorP h1{
        font-size: 18px;
    }

    .majorH1 h1{
        font-size: 22px;
        margin-bottom: 0;
    }

    .titlesmajorText{
        display: flex;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        width: 100%;
        margin-left: 0px;
    }

    .othermajorTitles{
        display: flex;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        width: 100%;
        margin-left: 0px;
    }


    .othermajor{
        display: grid;
        grid-template-columns: 70% 30%;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        width: 100%;
        align-items: center;
        color: white;
    }

    .majorRibbons{
        display: grid;
        grid-template-columns: 30% 70%;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        width: 100%;
        align-items: center;
        color: white;
    }

    .majorBox p{
        padding: 15px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        width: 80%;
    }

    .majorPara{
        font-size: 2.3vw !important;
    }
}
@media only screen and (max-width: 600px){

    .majorPara{
        font-size: 3.25vw !important;
    }

    .majorBox p{
        padding: 5px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        width: 95% !important;
    }

    .titlesmajorText{
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        width: 100%;
        margin-left: 0px;
    }

    .othermajorTitles{
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        width: 100%;
        margin-left: 0px;
    }


    .majorRibbons{
        display: grid;
        grid-template-columns: 1fr;
        margin-left: auto;
        margin-right: auto;
        padding: 0px;
        width: 95%;
        align-items: center;
        color: white;
    }

    .othermajor{
        display: grid;
        grid-template-columns: 1fr;
        margin-left: auto;
        margin-right: auto;
        padding: 0px;
        width: 95%;
        align-items: center;
        color: white;
    }

    .majorH1{
        margin-bottom: 0;
        background-color: #1eb6cf;
        margin-left: auto;
        margin-right: auto;
        width: 35%;
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 40%), 0 6px 20px 0 rgb(0 0 0 / 40%);
    }

    .othermajorH1{
        margin-bottom: 0;
        background-color: #1eb6cf;
        margin-left: auto;
        margin-right: auto;
        width: 35%;
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 40%), 0 6px 20px 0 rgb(0 0 0 / 40%);
    }

    .majorText:after{
        content: '';
        transform: rotate(180deg);
        display: block;
        position: absolute;
        background-color: white;
        width: 0; 
        height: 0px; 
        border-top: 0px solid #3c80b0;
        border-bottom: 0px solid #3c80b0;  
        border-left: 0px solid #3c80b0;
        right: 0;
        top: 0;
    }

    .othermajorText:after{
        content: '';
        transform: rotate(180deg);
        display: block;
        position: absolute;
        background-color: white;
        width: 0; 
        height: 0px; 
        border-top: 0px solid #3c80b0;
        border-bottom: 0px solid #3c80b0;  
        border-left: 0px solid #3c80b0;
        left: 0;
        top: 0;
    }
}


