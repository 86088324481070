.Spon-Links{
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    column-gap: 30px;
    width: 70%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 50px;
    padding-bottom: 0px;
    column-gap: 30px;
    row-gap: 30px

}

.Spon-Icon{
    grid-column: span 2; 
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    
}

.moveInSpan{
    grid-column: 2/span 2;
}

.Spon-Icon img{
    width: 100%;
}

.boxes-Spon{
    text-align: center;
    margin: 0px;
}

.triangle {
    margin-left: auto;
    margin-right: auto;
    width: 40px; 
    height: 60px; 
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;    
    border-bottom: 40px solid #1b527f;
}

#Spon_one{
    display: block;
}

#Spon_two{
    display: none;
}

#Spon_Three{
    display: none;
}

.hyundai{
    width:89% !important;
}

@media only screen and (max-width: 600px){




    .triangle {
        margin-left: auto;
        margin-right: auto;
        width: 20px; 
        height: 60px; 
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;    
        border-bottom: 20px solid #1b527f;
    }

    .moveInSpan{
        grid-column: 2/span 1;
    }

    .Spon-Icon{
        grid-column: span 1; 
        text-align: center;
        margin-right: auto;
        margin-left: auto;
        
    }

    .Spon-Links{
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 15px;
        width: 85%;
        margin-right: auto;
        margin-left: auto;
        padding-top: 50px;
        padding-bottom: 0px;
    }

}