.sub-background{
    background-color: #3c80b0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
}

.sub-box{
    color: white;
    padding: 15px 30px;
    text-align: left;
    width: 95%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.sub-box h3{
    font-size: 2.1vw;
    padding: 5px;
}

.sub-box p{
    font-size: 1.1vw;
    padding: 5px;
    margin-bottom: 0rem;
}

.subImageOne{
    width: 100%;
    height: 100%;
}


@media only screen and (max-width: 1550px){

    .sub-box h3{
        font-size: 1.9vw;
        padding: 5px;
    }
    
    .sub-box p{
        font-size: 1vw;
        padding: 5px;
        margin-bottom: 0rem;
    }

}

@media only screen and (max-width: 1410px){

    .sub-box{
        color: white;
        padding: 10px 20px;
        text-align: left;
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .sub-box h3{
        font-size: 1.8vw;
        padding: 5px;
    }
    
    .sub-box p{
        font-size: 1vw;
        padding: 5px;
        margin-bottom: 0rem;
    }
}


@media only screen and (max-width: 1000px){

    .sub-box{
        color: white;
        padding: 5px 20px;
        text-align: left;
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    
    .sub-box h3{
        font-size: 4vw;
        padding: 5px;
    }
    
    .sub-box p{
        font-size: 3vw;
        padding: 5px;
        margin-bottom: 0rem;
    }

    .sub-background{
        background-color: #3c80b0;
        display: grid;
        grid-template-columns: 1fr;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
    }
}