.nav-grid{
    display: grid;
    grid-template-columns: 35% 65%;
    align-items: center;    
}



.nav-mobile{
  display: none;
}

.logoSec{
    text-align: center;
}

.navSec{
    margin-left: auto;
    margin-right: auto;
}

.nav-grid a{
    text-decoration: none;
    color: black !important;
}

.nav-grid li, a {
    color: black;
}

nav a{
    color: black !important;
}

.nav-item {
    color: black !important;
    margin-left: 25px;
    font-size: 18px;
}

.coachDrop{
    display: none;
    z-index: 10000;
    position: absolute;
    text-decoration: none;
    list-style: none;
    text-align: left;
    padding-left: .5rem;
    top: 80%;
    background-color: #1b527f;
    padding: 7px 15px;
    border-radius: 4px;
    


}

.FollowDrop{
    display: none;
    z-index: 10000;
    position: absolute;
    text-decoration: none;
    list-style: none;
    text-align: left;
    padding-left: .5rem;
    top: 80%;
    background-color: #1b527f;
    padding: 7px 15px;
    border-radius: 4px;
}

.TourDrop{
    display: none;
    z-index: 10000;
    position: absolute;
    text-decoration: none;
    list-style: none;
    text-align: left;
    padding-left: .5rem;
    top: 80%;
    background-color: #1b527f;
    padding: 7px 15px;
    border-radius: 4px;
}

.ProDrop{
    display: none;
}

.coachDrop li a{
    color: white !important;
}

.coachDrop li a:hover{
    color: #1eb6cf !important;
}

.FollowDrop li a:hover{
    color: #1eb6cf !important;
}

.TourDrop li a:hover{
    color: #1eb6cf !important;
}

.FollowDrop li a{
    color: white !important;
}

.TourDrop li a{
    color: white !important;
}

.login-B{
    padding: 6px 18px;
    border: none;
    background-color: #1b527f;
    color: white;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.hex{
    color:#1eb6cf;
    background: #3c80b0;
    border: #1b527f;
}

@media only screen and (max-width: 1550px) {
    .nav-item {
        color: black !important;
        margin-left: 15px;
        font-size: 17px;
    }
}



@media only screen and (max-width: 1300px){

  .nav-item {
      color: black !important;
      margin-left: 12px;
      font-size: 17px;
  }


}


@media only screen and (max-width: 1250px){

    .nav-item {
        color: black !important;
        margin-left: 6px;
        font-size: 14px;
    }


}

@media only screen and (max-width: 1060px){

    .nav-item {
        color: black !important;
        margin-left: 4px;
        font-size: 13px;
    }


}

@media only screen and (max-width: 986px){

    .nav-item {
        color: black !important;
        margin-left: 0px;
        font-size: 16px;
        
    }

    .nav-grid a {
        color: white !important;
    }

  .navSec{
    display: none;
}


  .nav-mobile{
    display: block;
    padding-right: 25px;
  }

  .hamburger{
    float: right;
  }


    * {
        margin: 0;
        padding: 0; 
      }
      
      


}

