.kyle-boxes{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    column-gap: 35px;
    
}

.kyle-boxOne{
    display: grid;
    grid-template-rows: auto auto;
    margin-left: auto;
    margin-right: auto;
    
}

.kyle-boxTwo{
    display: grid;
    grid-template-rows: auto auto;
    margin-left: auto;
    margin-right: auto;
   
}

.kyle-images{
    width: 100%;
    height: 100%;
}

.story-box{
    background-color: #3c80b0;
    color: white;
    padding: 30px 60px;
    text-align: left;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.15vw;

}

.storyTwo-box{
    background-color: #3c80b0;
    color: white;
    padding: 30px 60px;
    text-align: left;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.15vw;

}

@media only screen and (max-width: 1500px){

    .story-box{
        background-color: #3c80b0;
        color: white;
        padding: 10px 30px;
        text-align: left;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 1.15vw;
    
    }
    
    .storyTwo-box{
        background-color: #3c80b0;
        color: white;
        padding: 10px 30px;
        text-align: left;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 1.15vw;
    
    }

}
@media only screen and (max-width: 1000px){

    .kyle-boxes{
        display: grid;
        grid-template-columns: 1fr;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        column-gap: 35px;
        row-gap: 25px;
        
    }

    .story-box{
        background-color: #3c80b0;
        color: white;
        padding: 10px 30px;
        text-align: left;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 1.75vw;
    
    }
    
    .storyTwo-box{
        background-color: #3c80b0;
        color: white;
        padding: 10px 30px;
        text-align: left;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 1.75vw;
    
    }

}
@media only screen and (max-width: 600px){

    .kyle-boxes{
        display: grid;
        grid-template-columns: 1fr;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        column-gap: 35px;
        row-gap: 25px;
        
    }

    .story-box{
        background-color: #3c80b0;
        color: white;
        padding: 10px 30px;
        text-align: left;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 3.5vw;
    
    }
    
    .storyTwo-box{
        background-color: #3c80b0;
        color: white;
        padding: 10px 30px;
        text-align: left;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 3.5vw;
    
    }

}