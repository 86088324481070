.tree-boxes{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
}

.tree-box{
    background-color: #3c80b0;
    color: white;
    padding: 20px 40px;
    text-align: left;
    width: 95%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.tree-box h2{
    font-size: 2.0vw;
    margin-bottom: 30px;
}

.tree-button{
    text-align: left;
}


@media only screen and (max-width: 1480px){

    .tree-box{
        background-color: #3c80b0;
        color: white;
        padding: 15px 30px;
        text-align: left;
        width: 97%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }

}



@media only screen and (max-width: 1300px){
    .tree-box h2{
        font-size: 2.0vw;
        margin-bottom: 10px;
    }

}


@media only screen and (max-width: 1000px){

    .tree-button{
        text-align: center;
    }

    .tree-box h2{
        font-size: 2.8vw;
        margin-bottom: 10px;
        text-align: center;

    }

    .tree-box{
        background-color: #3c80b0;
        color: white;
        padding: 15px 30px;
        text-align: left;
        width: 75%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .tree-boxes{
        display: grid;
        grid-template-columns: 1fr;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        row-gap: 15px;
    }

}


@media only screen and (max-width: 600px){

    .tree-box h2{
        font-size: 4.3vw;
        margin-bottom: 5px;
        text-align: center;

    }

    .tree-box{
        background-color: #3c80b0;
        color: white;
        padding: 10px 10px;
        text-align: left;
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}
