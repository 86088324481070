.kitchen-table{
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 100%;

}

.kitchen-table thead{
    background-color: #1b527f;
    color: white;
}

.kitchen-table thead th{
    padding: 15px;
    font-size: 1.3vw;
}

.thBen{
    text-align: left;
    padding-left: 75px !important;
}

.kitchen-table tbody{
    background-color: #3c80b0;
    color: white;
    border: 4px solid lightgrey;
}

.kitchen-table td{
    padding: 10px;
    font-size: 1.1vw;
    border: 4px solid lightgrey;
}

.kitchen-table .firstTD{
    text-align: left;
    padding-left: 75px !important;
}



@media only screen and (max-width: 1000px){

    .kitchen-table thead th{
        padding: 15px;
        font-size: 1.9vw;
    }

    .kitchen-table td{
        padding: 10px;
        font-size: 1.5vw;
        border: 4px solid lightgrey;
    }
}

@media only screen and (max-width: 700px){

    .kitchen-table .firstTD{
        text-align: left;
        padding-left: 15px !important;
    }

    .thBen{
        text-align: left;
        padding-left: 15px !important;
    }

    .kitchen-table thead th{
        padding: 5px;
        font-size: 2.75vw;
    }

    .kitchen-table td{
        padding: 10px;
        font-size: 2.1vw;
        border: 4px solid lightgrey;
    }
       

}