.gradient-bar{
    background-image: linear-gradient(to right, #1b527f, #3c80b0, #1eb6cf);
    width: 100%;
    height: 12px;
}

.hex{
    color:#1eb6cf;
    background: #3c80b0;
    border: #1b527f;
}

.page-Online{
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: 30px;
    color: #1b527f;
}

.page-Online h1{
    font-size: 3rem;
    font-weight: bold;
}


.middleHeader{
    padding: 37px;
    color: #1b527f;
    font-weight: bold;
}

.clinic_button{
    padding: 15px 30px;
    border-radius: 35px;
    border: none;
    background-color: #1eb6cf;
    color: white;  
    margin: 5px; 
    font-size: 21px;
}

@media only screen and (max-width: 1000px){

    .page-Online h1{
        font-size: 2rem;
        font-weight: bold;
    }

    .page-Online{
        text-align: left;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        margin-top: 30px;
        color: #1b527f;
    }

    
    .clinic_button{
        padding: 15px 30px;
        border-radius: 35px;
        border: none;
        background-color: #1eb6cf;
        color: white;  
        margin: 5px; 
        font-size: 18px;
    }

}