.top-header{
    background-color: #1b527f;
    padding: 20px;
    text-align: left;        
    color: white;
    font-size: 23px;
    margin-bottom: 0;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

}

.top-header h1{
    margin-left: 30px;
}

.follow-bg {
    background-color: lightgrey;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    
}

.nike-boxes{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
}

.nikeBoxOne{
    background-color: #1b527f;
    color: white;
    padding: 40px 60px;
    text-align: left;
    width: 92%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.nikeBoxTwo{
    width: 92%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.nikeOneImg {
    width: 100%;
}

.nike-main{
    width: 100%;
    object-fit: cover;
    height: 300px;
}

.nikeBoxOne p{
    font-size: 1.1vw;
    padding: 8px;
 }
 
 .nikeBoxOne h1{
    font-size: 2.1vw;
    padding: 8px;
 }

 .nike-big-image{
     width: 93%;
     margin-left: auto;
     margin-right: auto;
 }


 @media only screen and (max-width: 1600px){

    .nikeBoxOne{
        background-color: #1b527f;
        color: white;
        padding: 30px 50px;
        text-align: left;
        width: 92%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .nikeBoxOne p{
        font-size: 1.3vw;
        padding: 8px;
     }
     
     .nikeBoxOne h1{
        font-size: 2.1vw;
        padding: 8px;
     }

 }

 @media only screen and (max-width: 1000px){

    .nikeBoxOne p{
        font-size: 3vw;
        padding: 8px;
     }
     
     .nikeBoxOne h1{
        font-size: 3.6vw;
        padding: 8px;
     }
    .nike-boxes{
        display: grid;
        grid-template-columns: 1fr;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        row-gap: 30px;
    }

    .nikeBoxOne{
        background-color: #1b527f;
        color: white;
        padding: 20px 30px;
        text-align: left;
        width: 95%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    
    .nikeBoxTwo{
        width: 95%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    
    .top-header{
        background-color: #1b527f;
        padding: 20px;
        text-align: left;        
        color: white;
        font-size: 23px;
        margin-bottom: 0;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    
    }
    
    .top-header h1{
        margin-left: 30px;
    }
    
    .follow-bg {
        background-color: lightgrey;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        
    }
}

@media only screen and (max-width: 600px){

    
}