.callie-boxes{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    column-gap: 35px;
    
}

.callie-boxOne{
    display: grid;
    grid-template-rows: auto auto;
    margin-left: auto;
    margin-right: auto;
    
}

.callie-boxTwo{
    display: grid;
    grid-template-rows: auto auto;
    margin-left: auto;
    margin-right: auto;
   
}

.callie-images{
    width: 100%;
    height: 100%;
}

.tennis-box{
    background-color: #3c80b0;
    color: white;
    padding: 30px 60px;
    text-align: left;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.15vw;

}

.pickleball-box{
    background-color: #3c80b0;
    color: white;
    padding: 30px 60px;
    text-align: left;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.15vw;

}

@media only screen and (max-width: 1350px){

    .tennis-box{
        background-color: #3c80b0;
        color: white;
        padding: 10px 30px;
        text-align: left;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 1.15vw;
    
    }

    .pickleball-box{
        background-color: #3c80b0;
        color: white;
        padding: 10px 30px;
        text-align: left;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 1.15vw;
    
    }

}
@media only screen and (max-width: 1000px){

    .callie-boxes{
        display: grid;
        grid-template-columns: 1fr;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        column-gap: 35px;
        row-gap: 25px;
        
    }

    .tennis-box{
        background-color: #3c80b0;
        color: white;
        padding: 10px 30px;
        text-align: left;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 2vw;
    
    }

    .pickleball-box{
        background-color: #3c80b0;
        color: white;
        padding: 10px 30px;
        text-align: left;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 2vw;
    
    }

}
@media only screen and (max-width: 600px){

    .tennis-box{
        background-color: #3c80b0;
        color: white;
        padding: 5px 10px;
        text-align: left;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 3.5vw;
    
    }

    .pickleball-box{
        background-color: #3c80b0;
        color: white;
        padding: 10px 10px;
        text-align: left;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 3.5vw;
    
    }

    .callie-boxes{
        display: grid;
        grid-template-columns: 1fr;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
        column-gap: 5px;
        row-gap: 15px;
        
    }

}
