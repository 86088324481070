.schedule-item {
    width: 95%;
    background-color: white;
    margin-right: auto;
    margin-left: auto;
    padding: 9.5px;
    display: grid;
    grid-template-columns: 10% 25% 50% 15%;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.schedule-margin{
    margin: 12.5px;
}

.location-icon{
    width: 10%;
}

.s-button{
    text-align: center;
    margin-right: auto;
    margin-left: auto;
}

.s-link{
    float: right;
    padding: 4px 18px;
    border-radius: 35px;
    border: none;
    background-color: #1eb6cf;
    color: white;  
    margin: 5px; 
    font-size: 16px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 2px;
}

.s-date{
    border-right: #1b527f 2px solid;
    font-size: 1.25vw;
    font-weight: bolder;
}

.s-date p{
    margin: 0px;
}

.s-date p.date-bottom{
    font-weight: lighter;
}


.s-time{
    font-size: .90vw;
    font-weight: bolder;
}

.p-time{
    font-weight: lighter;
    margin: 0px;
}


.s-location{
    font-size: .95vw;
    font-weight: bold;
    
}
@media only screen and (max-width: 1500px){
.s-link{
    float: right;
    padding: 4px 17px;
    border-radius: 35px;
    border: none;
    background-color: #1eb6cf;
    color: white;  
    margin: 5px; 
    font-size: 14px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 2px;
}

.s-date{
    border-right: #1b527f 2px solid;
    font-size: 1.35vw;
    font-weight: bolder;
}

.s-date p{
    margin: 0px;
}

.s-date p.date-bottom{
    font-weight: lighter;
}


.s-time{
    font-size: 1.0vw;
    font-weight: bolder;
}

.p-time{
    font-weight: lighter;
    margin: 0px;
}


.s-location{
    font-size: 1.0vw;
    font-weight: bold;
    
}

}

@media only screen and (max-width: 1300px){
    .s-link{
        float: right;
        padding: 5px 20px;
        border-radius: 35px;
        border: none;
        background-color: #1eb6cf;
        color: white;  
        margin: 5px; 
        font-size: 13px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        margin: 2px;
    }

}


@media only screen and (max-width: 1000px){

    .location-icon{
        width: 20%;
        display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    }

    .s-date{
        border-right: white 2px solid;
        font-size: 4.5vw;
        font-weight: bolder;
    }
    
    .s-date p{
        margin: 5px;
        display: inline-block;
    }
    
    .s-date p.date-bottom{
        font-weight: lighter;
    }
    
    
    .s-time{
        font-size: 3.4vw;
        font-weight: bolder;
    }
    
    .p-time{
        font-weight: lighter;
    }
    
    
    .s-location{
        font-size: 3.4vw;
        font-weight: bold;
        
    }

    .schedule-item {
        align-items: center;
        background-color: #fff;
        display: grid;
        grid-template-rows: auto auto auto auto;
        grid-template-columns: 1fr;
        margin-left: auto;
        margin-right: auto;
        padding: 7.5px;
        width: 100%;
        row-gap: 10px;
        text-align: center;
    }

    .s-link{
        float: right;
        padding: 5px 10px;
        border-radius: 35px;
        border: none;
        background-color: #1eb6cf;
        color: white;
        margin: 1px;
        font-size: 18px;
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
        margin-right: 0px;
    }

}

    @media only screen and (max-width: 450px){

        .s-link{
            float: right;
            padding: 3.5px 8px;
            border-radius: 35px;
            border: none;
            background-color: #1eb6cf;
            color: white;
            margin: 1px;
            font-size: 17px;
            box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
            margin-right: 0px;
        }  

}
