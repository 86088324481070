.social-header{
    background-color: #1b527f;
    padding: 20px;
    text-align: center;        
    color: white;
    font-size: 23px;
    margin-bottom: 0;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

}

.top-header h1{
    margin-left: 30px;
}

.kitchen-bg {
    background-color: lightgrey;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    padding: 50px;
    
}

.socialAbout{
    text-align: center;
}

.social-box{
    height: auto;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    width: 90%;
    background-color: #1b527f;
}

.car-box{
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.car-box h2{
    font-size: 1vw;;
}

.social-boxTwo{
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    width: 90%;
    background-color: #3c80b0;
}

.socialTop{
    
    margin-bottom: 75px;
}

.social-box h2{
    color: white;
    font-size: 20px;
}



@media only screen and (max-width: 1420px){

   
}


@media only screen and (max-width: 1000px){

    .social-box{
        height: auto;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
        width: 95%;
        background-color: #1b527f;
    }

    .social-box h2{
        color: white;
        font-size: 18px;
    }

    .social-header{
        background-color: #1b527f;
        padding: 20px;
        text-align: center;        
        color: white;
        font-size: 23px;
        margin-bottom: 0;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    
    }

   
    .top-header{
        background-color: #1b527f;
        padding: 20px;
        text-align: left;        
        color: white;
        font-size: 23px;
        margin-bottom: 0;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    
    }
    
    .top-header h1{
        margin-left: 30px;
    }
    
    
    .kitchen-bg {
        background-color: lightgrey;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 25px;
        
    }

    
}

@media only screen and (max-width: 550px){

    


}