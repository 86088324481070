.clinics_b{
    background-color: #1b527f;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    
}

.pad-box{
    padding-left: 20px;
}

.border-line{
    border-left: 1px solid white;    
}

.clinics_boxes{
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* column-gap: 25px; */
    align-items: center;
    padding: 25px;
}


.clinics_box{
    background-color: #3c80b0;
    height: 100%;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
}


.h1_clincis{
    color: white;
    padding: 15px;
    margin-left: 30px;
    text-align: left;
}

.p-clinics{
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: left;
    padding: 30px;
    font-size: 20px;
    color: white;
    margin-top: 25px;
    margin-bottom: 25px;
}

.clinics-img{
    width: 100%;
}

.bold-clinics{
    text-align: left;
    font-size: 25px;
    color: white;
    padding-left: 35px;
}


.clincis_g{
    background-color: lightgrey;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.h3_clinics{
    background-color: #3c80b0;
    width: 100%;
    padding: 20px;
    text-align: center;
    color: white;
    font-size: 23px;
    margin-bottom: 0;
}

.instruct{
    display: grid;
    grid-template-columns: 47.5% 5% 47.5%;
    align-items: center;
}

.instruct h1{
    color:#3c80b0;
}

.clinics_g_h3{
    padding-top: 45px;
    padding-right: 45px;
    padding-left: 45px;
    font-size: 25px;
}

.clinics_online_p{
    font-size: 17px;
    width: 50%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.clinics_private_p{
    font-size: 17px;
    width: 40%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: 25px;
    padding-bottom: 10px;
}

.clinics_callie_p{
    font-size: 17px;
    width: 40%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: 25px;
    padding-bottom: 10px;
}

.clinic_button{
    padding: 11px 30px;
    border-radius: 30px;
    border: none;
    background-color: #1eb6cf;
    color: white;  
    margin: 30px;  
}

.grey_line{
    background-color: grey;
    height: 1.5px;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}


@media only screen and (max-width: 1720px){
    .p-clinics{
        margin-top: 0;
        margin-bottom: 1rem;
        text-align: left;
        padding: 25px;
        font-size: 16px;
        color: white;
        margin-top: 25px;
        margin-bottom: 25px;
    }
}

@media only screen and (max-width: 1430px){

    .clinics_online_p{
        font-size: 17px;
        width: 70%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    
    .clinics_private_p{
        font-size: 17px;
        width: 50%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    
    .clinics_callie_p{
        font-size: 17px;
        width: 50%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .clinics_box{
        background-color: #3c80b0;
        height: 100%;
        width: 95%;
        margin-right: auto;
        margin-left: auto;
        align-items: center;
    }

    .p-clinics{
        margin-top: 0;
        margin-bottom: 1rem;
        text-align: left;
        padding: 18px;
        font-size: 15px;
        color: white;
        margin-top: 15px;
        margin-bottom: 15px;
    }
}


@media only screen and (max-width: 1330px){

    .clinics_b{
        background-color: #1b527f;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .clincis_g{
        background-color: lightgrey;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .clinics_box{
        background-color: #3c80b0;
        height: 100%;
        width: 95%;
        margin-right: auto;
        margin-left: auto;
        align-items: center;
    }

    .p-clinics{
        margin-top: 0;
        margin-bottom: 1rem;
        text-align: left;
        padding: 12px;
        font-size: 14px;
        color: white;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 970px){

    .clinic_button{
        padding: 11px 20px;
        border-radius: 30px;
        border: none;
        background-color: #1eb6cf;
        color: white;  
        margin: 10px; 
        font-size: 15px;
    }

    .p-clinics{
        margin-top: 0;
        margin-bottom: 1rem;
        text-align: left;
        padding: 12px;
        font-size: 15px;
        color: white;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .clinics_b{
        background-color: #1b527f;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .clincis_g{
        background-color: lightgrey;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    
        .h1_clincis{
            color: white;
            padding: 15px;
            margin-left: 5px;
            text-align: left;
        }

    .clinics_boxes{
        display: grid;
        grid-template-columns: 1fr;
        /* column-gap: 25px; */
        align-items: center;
        padding: 25px;
        row-gap: 25px;
    }

    .clinics_box{
        background-color: #3c80b0;
        height: 100%;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        align-items: center;
        
    }

    .clinics-img{
        width: 100%;
    }

    .clinics_b{
        background-color: #1b527f;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .clincis_g{
        background-color: lightgrey;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .clinics_online_p{
        font-size: 13px;
        width: 75%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    
    .clinics_private_p{
        font-size: 13px;
        width: 60%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    
    .clinics_callie_p{
        font-size: 13px;
        width: 75%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .h3_clinics{
        background-color: #3c80b0;
        width: 100%;
        padding: 18px;
        text-align: center;
        color: white;
        font-size: 20px;
        margin-bottom: 0;
    }

}