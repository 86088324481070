.header-tour{
    background-color: #1b527f;
    padding: 20px;
    text-align: left;        
    color: white;
    font-size: 23px;
    margin-bottom: 0;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

}

.tour-bg {
    background-color: lightgrey;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    
}

.tour-boxes{
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* column-gap: 25px; */
    align-items: center;
    padding: 25px;
}

.tour-box{
    background-color: #3c80b0;
    height: 100%;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
}

.tour-button-two{
    padding: 11px 30px;
    border-radius: 30px;
    border: none;
    background-color: #1eb6cf;
    color: white;  
    margin-top: 25px; 
}

.tour-button{
    padding: 11px 30px;
    border-radius: 30px;
    border: none;
    background-color: #1eb6cf;
    color: white;  
    margin-top: 25px; 
}

.tour-img{
    width: 100%;
}

.tour-p{
    width: 60%;
    font-size: 20px;
    padding: 70px;
    margin-left: auto;
    margin-right: auto;
}

.pad-tour{
    padding-left: 20px;
    padding: 10px;
    color: white;
    text-align: left;
    padding: 40px;
    font-size: 20px;
}

.header-tour h1{
    margin-left: 30px;
}


@media only screen and (max-width: 1760px){
    .pad-tour{
        padding-left: 20px;
        padding: 10px;
        color: white;
        text-align: left;
        padding: 30px;
        font-size: 18px;
    }

    .tour-button-two{
        padding: 11px 30px;
        border-radius: 30px;
        border: none;
        background-color: #1eb6cf;
        color: white;  
        margin-top: 15px; 
    }

}


@media only screen and (max-width: 1540px){

    .tour-p{
        width: 70%;
        font-size: 20px;
        padding: 70px;
        margin-left: auto;
        margin-right: auto;
    }
    .pad-tour{
        padding-left: 20px;
        padding: 10px;
        color: white;
        text-align: left;
        padding: 25px;
        font-size: 16px;
    }

    .tour-button-two{
        padding: 11px 30px;
        border-radius: 30px;
        border: none;
        background-color: #1eb6cf;
        color: white;  
        margin-top: 12px; 
    }

    
.tour-box{
    background-color: #3c80b0;
    height: 100%;
    width: 95%;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
}

}

@media only screen and (max-width: 1330px){

    .pad-tour{
        padding-left: 20px;
        padding: 10px;
        color: white;
        text-align: left;
        padding: 20px;
        font-size: 14px;
    }

    .tour-p{
        width: 75%;
        font-size: 20px;
        padding: 80px;
        margin-left: auto;
        margin-right: auto;
    }

    .header-tour{
        background-color: #1b527f;
        padding: 20px;
        text-align: left;        
        color: white;
        font-size: 23px;
        margin-bottom: 0;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    
    }
    
    .tour-bg {
        background-color: lightgrey;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        
    }
 
}

@media only screen and (max-width: 1060px){
   
    .pad-tour{
        padding-left: 20px;
        padding: 10px;
        color: white;
        text-align: left;
        padding: 20px;
        font-size: 13px;
    }
}



@media only screen and (max-width: 970px){

    .pad-tour{
        padding-left: 20px;
        padding: 10px;
        color: white;
        text-align: left;
        padding: 20px;
        font-size: 15px;
    }

    
    .header-tour{
        background-color: #1b527f;
        padding: 20px;
        text-align: left;        
        color: white;
        font-size: 23px;
        margin-bottom: 0;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    
    }
    
    .tour-bg {
        background-color: lightgrey;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        
    }
    .header-tour h1{
        margin-left: 5px;
    }

    .tour-p{
        width: 90%;
        font-size: 15px;
        padding: 40px;
        margin-left: auto;
        margin-right: auto;
    }

    .tour-boxes{
        display: grid;
        grid-template-columns: 1fr;
        /* column-gap: 25px; */
        align-items: center;
        padding: 25px;
        row-gap: 25px;
    }
    
    .tour-box{
        background-color: #3c80b0;
        height: 100%;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        align-items: center;    
    }
}
