.onix-box{
    background-color: #3c80b0;
    color: white;
    padding: 10px 20px;
    text-align: left;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.onix-box h2{
    margin: 25px;
    padding: 40px;
    text-align: center;
}

.onix-button{
    text-align: center;
}


@media only screen and (max-width: 850px){

    .onix-box h2{
        font-size: 4.9vw;
        margin: 5px;
        padding: 10px;
        text-align: center;
    }

}