

#dropDownMobile{
    z-index: 5;
    top: 14%;
    position: absolute;   
    transform: scale(1);
    background-color: #1b527f;
    color: white;
    text-align: center;
    width: 100%;
    list-style: none;
    max-height:0px;
   overflow:hidden;
   position:absolute;
   -webkit-transition:max-height .2s ease-in-out .2s;
   -moz-transition:max-height .2s ease-in-out .2s;
   -o-transition:max-height .2s ease-in-out .2s;
   -ms-transition:max-height .2s ease-in-out .2s;
   transition:max-height .2s ease-in-out .2s;    
}



