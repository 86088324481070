.top-header{
    background-color: #1b527f;
    padding: 20px;
    text-align: left;        
    color: white;
    font-size: 23px;
    margin-bottom: 0;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

}

.top-header h1{
    margin-left: 30px;
}

.kitchen-bg {
    background-color: lightgrey;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    padding: 50px;
    
}

.why-boxes{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
}

.why-boxes h3{
    padding: 10px;
    font-size: 1.65vw;
}

.why-boxOne{
    background-color: #3c80b0;
    color: white;
    padding: 20px 40px;
    text-align: left;
    width: 95%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.why-boxOne p{
    padding: 10px;
    font-size: 1.3vw;;
}

.why-boxTwo{
    width: 95%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.whyImageOne{
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 1420px){

    .why-boxes h3{
        padding: 10px;
        font-size: 1.55vw;
    }

    .why-boxOne p{
        padding: 10px;
        font-size: 1.2vw;;
    }
}


@media only screen and (max-width: 1000px){

    .why-boxes h3{
        padding: 10px;
        font-size: 3vw;
    }
    .why-boxOne{
        background-color: #3c80b0;
        color: white;
        padding: 20px 40px;
        text-align: left;
        width: 95%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .why-boxOne p{
        font-size: 2.2vw;;
    }

    .top-header{
        background-color: #1b527f;
        padding: 20px;
        text-align: left;        
        color: white;
        font-size: 23px;
        margin-bottom: 0;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    
    }
    
    .top-header h1{
        margin-left: 30px;
    }
    
    
    .kitchen-bg {
        background-color: lightgrey;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 25px;
        
    }

    .why-boxes{
        display: grid;
        grid-template-columns: 1fr;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        row-gap: 50px;
    }

}

@media only screen and (max-width: 550px){

    .why-boxes{
        display: grid;
        grid-template-columns: 1fr;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        row-gap: 50px;
    }

    .why-boxes h3{
        padding: 10px;
        font-size: 3.5vw;
    }

    .why-boxOne p{
        font-size: 2.7vw;;
    }
    .why-boxTwo{
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .why-boxOne{
        background-color: #3c80b0;
        color: white;
        padding: 20px 40px;
        text-align: left;
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }


}