.top-header{
    background-color: #1b527f;
    padding: 20px;
    text-align: left;        
    color: white;
    font-size: 23px;
    margin-bottom: 0;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

}

.top-header h1{
    margin-left: 30px;
}

.follow-bg {
    background-color: lightgrey;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    
}

.paddle-boxes{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;

}

.paddle-box{
    width: 92%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.secondClincImg{
    width: 100%;
    height: 100%;
}

.firstClincImg{
width: 100%;
}

.schedule-clinic{
    background-color: #1b527f;
    color: white;
    padding: 40px 60px;
    text-align: left;
}

.paddle-quote{
    margin-left: auto;
    margin-right: auto;
}

.clinic-p{
    background-color: #3c80b0;
    color: white;
    padding: 20px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    width: 93%;
}

.clinic-p p{
    padding: 5px 20px;
    font-size: 1vw;
}

.clinic-p h3{
    padding: 5px 20px;
    font-size: 1.3vw;
}
.schedule-clinic p{
   font-size: 1.1vw;
   padding: 8px;
}

.schedule-clinic h1{
   font-size: 2.1vw;
   padding: 8px;
}




@media only screen and (max-width: 1000px){

    .schedule-clinic p{
        font-size: 3vw;
        padding: 8px;
     }
     
     .schedule-clinic h1{
        font-size: 4.2vw;
        padding: 8px;
     }
     

    .paddle-boxes{
        display: grid;
        grid-template-columns: 1fr;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
    
    }

    .schedule-clinic{
        background-color: #1b527f;
        color: white;
        padding: 30px 50px;
        text-align: left;
    }
    
    .paddle-box{
        width: 95%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .clinic-p p{
        padding: 5px 20px;
        font-size: 1.5vw;
    }
    
    .clinic-p h3{
        padding: 5px 20px;
        font-size: 1.9vw;
    }

    .top-header{
        background-color: #1b527f;
        padding: 20px;
        text-align: left;        
        color: white;
        font-size: 23px;
        margin-bottom: 0;
        width: 99%;
        margin-left: auto;
        margin-right: auto;
    
    }
    
    .top-header h1{
        margin-left: 30px;
    }
    
    .follow-bg {
        background-color: lightgrey;
        width: 99%;
        margin-left: auto;
        margin-right: auto;
        
    }
    
}

@media only screen and (max-width: 600px){
    .clinic-p p{
        padding: 5px 20px;
        font-size: 2.5vw;
    }
    
    .clinic-p h3{
        padding: 5px 20px;
        font-size: 3vw;
    }
    
}